import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  Renderer2,
  DOCUMENT
} from '@angular/core';
import { ConfigService } from '@scpc/modules/common/services/config.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';
import { StorageService } from '@scpc/modules/common/services';
import { TranslateModule } from '@ngx-translate/core';
import { ClockComponent } from '@scpc/modules/common/components/clock/clock.component';
import { VisibilityService } from '@scpc/modules/common/services/visibility.service';
import { RouterLink, RouterLinkActive } from '@angular/router';

@Component({
  selector: 'scp-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RouterLink,
    RouterLinkActive,
    TranslateModule,
    ClockComponent,
  ],
})
export class FooterComponent implements OnInit, OnDestroy {

  public items: { title: string; route: string, external?: boolean }[] = [];
  public content: SafeHtml | null;
  public readonly isNativeApp: boolean = this.storageService.device === 'ios' || this.storageService.device === 'android';

  constructor(private readonly configService: ConfigService,
              private readonly domSanitizer: DomSanitizer,
              @Inject(PLATFORM_ID) private readonly platformId: string,
              @Inject(DOCUMENT) private readonly document: Document,
              private readonly renderer: Renderer2,
              private readonly elementRef: ElementRef,
              private readonly storageService: StorageService,
              private readonly visibilityService: VisibilityService) {
  }

  public ngOnInit(): void {
    if (!this.isNativeApp && this.configService.hasApps) {
      this.items.push({ route: '/mobile', title: 'TITLE.APPS' });
    }
    if (this.configService.hasAboutUs) {
      this.items.push({ route: '/about-us', title: 'TITLE.ABOUT_US' });
    }
    if (this.configService.hasTerms) {
      this.items.push({ route: '/terms', title: 'TITLE.TERMS_AND_CONDITIONS' });
    }
    if (this.configService.hasPrivacyPolicy) {
      this.items.push({ route: '/privacy-policy', title: 'TITLE.PRIVACY_POLICY' });
    }
    if (this.configService.hasPoPIA) {
      this.items.push({ route: '/protection-of-personal-information-act', title: 'TITLE.POPIA' });
    }
    if (this.configService.hasResponsibleGambling) {
      this.items.push({ route: '/responsible-gambling-policy', title: 'TITLE.RESPONSIBLE_GAMBLING' });
    }
    if (this.configService.hasRules) {
      this.items.push({ route: '/rules', title: 'TITLE.RULES' });
    }
    if (this.configService.hasRegulations) {
      this.items.push({ route: '/regulations', title: 'TITLE.REGULATIONS' });
    }
    if (this.configService.hasFAQ) {
      this.items.push({ route: '/faq', title: 'TITLE.FAQ' });
    }
    if (this.configService.affiliateProgram) {
      this.items.push({ route: this.configService.affiliateProgram, title: 'TITLE.AFFILIATE_PROGRAM', external: true });
    }
    if (this.configService.hasContacts) {
      this.items.push({ route: '/contact-us', title: 'TITLE.CONTACT_US' });
    }
    if (this.configService.hasCares) {
      this.items.push({ route: '/yescares', title: 'TITLE.CARES' });
    }
    if (this.configService.hasBlog) {
      this.items.push({ route: '/blog', title: 'TITLE.BLOG' });
    }
    this.content = this.configService.footer ? this.domSanitizer.bypassSecurityTrustHtml(this.configService.footer) : null;
    this.addWidgets();
  }

  public ngOnDestroy(): void {
    this.visibilityService.unobserve(this.elementRef.nativeElement);
  }

  /* istanbul ignore next */
  private addWidgets(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.visibilityService.observe(this.elementRef.nativeElement, isVisible => {
        if (isVisible) {
          this.visibilityService.unobserve(this.elementRef.nativeElement);
          if (this.configService.reviews) {
            for (const review of this.configService.reviews) {
              if (review.widget) {
                const script = this.renderer.createElement('script');
                script.async = true;
                script.type = 'text/javascript';
                script.src = review.widget.src;
                script.id = review.widget.id;
                if (review.service === 'trustpilot') {
                  script.onload = (): void => {
                    const trustboxRef: HTMLElement = this.document.getElementById('trustbox');
                    window['Trustpilot'].loadFromElement(trustboxRef, 'trustbox');
                  };
                }
                for (const attribute of review.widget.attr) {
                  script.setAttribute(attribute.name, attribute.value);
                }
                this.renderer.appendChild(this.elementRef.nativeElement, script);
              }
            }
          }
        }
      });
    }
  }

}
